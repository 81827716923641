import { arraysShareElements } from "../../utils/utilities.js";
import eventDispatch from "../components/utils/eventDispatch.js";
// import scrollHide from "./scrollHide.js";

const getStore = () => {
    return JSON.parse(localStorage.getItem("store"))
}

const updateStore = payload => {
    const store = getStore() || {}
    localStorage.setItem("store", JSON.stringify({
        ...store,
        ...payload
    }))
}


/*

{
    period: "36 kk",
    cart: [{
        uid: "HANDSET-1234",
        camapignCode: ["SOME_EPIC_CAMPAIGN"],
        period: 12, // only for HANDSETS
        additionalProducts : [
            {
                uid: "product-1234",
                camapignCode: ["SOME_EPIC_CAMPAIGN"],
            }
        ]
    }],
    variants:{
        "iphone 14 pro 128 gb": "HANDSET-16543"
    },
    history: [{
        productCard??
    }]
}

*/

(() => {

    const initStore = () => {
        if(!localStorage.getItem("store")) {
            updateStore({
                period: 12,
                cart: [],
                history: [],
                variants: {}
            })
        }
    }

    const initPeriod = () => {
        const { period } = getStore()
        const periodSelect = document.querySelector(`[data-period="${period}"]`) || document.querySelector(`.price-box-label`)
        if(periodSelect) {
            periodSelect.click()
        }
    }

    const initVariant = () => {
        const { variants } = getStore()
        const product = document.querySelector(`.color-variants[data-product]`)
        if(product){
            const productUid = variants[product.dataset.product]
            const variantSelect = product.querySelector(`[data-uid="${productUid}"]`) || product.querySelector(`label`)
            if(variantSelect && !variantSelect.checked) {
                variantSelect.click()
            }
        }
    }

    const variantFocus = uid => {
        const variant = document.querySelector(`[data-uid="${uid}"]`)
        if(variant) {
            variant.focus()
        }
    }

    const pageLoad = () => {
        window.state = {}
        initStore();
        // initVariant();
        initPeriod();
    }

    document.addEventListener("DOMContentLoaded", pageLoad)

    const rootClassList = document.documentElement.classList
    let previousY = window.scrollY;
    const scrollHide = () => {
        const currentY = window.scrollY;
        const offset = 200
        if(currentY > previousY){
            // scrolling down, hide the navigation
            if(currentY > offset){
                rootClassList.add("scroll-hide")
            }
        // }else if(currentY > document.documentElement.getBoundingClientRect().height){
        }else{
            // scrolling up, show the navigation
            rootClassList.remove("scroll-hide")            
        }
        if(currentY <= 0){
            rootClassList.add("top")
        }else{
            rootClassList.remove("top")
        }        
        previousY = currentY;
    }
    scrollHide()
    window.addEventListener("scroll", scrollHide) 
    
    document.addEventListener("webshop", async ({
        action, 
        payload
    }) => {
        const store = getStore()
        const { cart, period } = store
        const cartBtn = document.getElementById("cart")

        if(action === "period:select") {
            updateStore({ period: payload })
        }else if(action === "variant:select") {
            // updateStore({variants: {
            //     ...getStore().variants,
            //     ...payload
            // }})
            updateStore({
                variants: {
                    ...getStore().variants,
                    [payload.name]: payload.uid
                }
            })
            initPeriod();
        }else if(action === "variant:focus") {
            variantFocus(payload)
        }else if(action === "cart:add") {

//     {
//       "type": "mobilePhone",
//       "productChoices": [
//         {
//           "productUid": "HANDSET-11456",
//           "productType": "mobilePhone",
//           "campaignIds": [
//             20010
//           ],
//           "campaignCode": [
//             "SOME_CAMPAIGN"
//           ],
//           "priceId": 157731
//         }
//       ]
//     },

            const { uid, code, period } = payload
            const data = await fetch(`/api/devices/${payload.uid}.json`)
            const product = await data.json()
            const { type, priceOptions, campaigns } = product
            let activeCampaign = null
            if(payload.code) {
                try {
                    const ldbRaw = await fetch(`https://ldb.csf.elisa.fi/v1/campaigns/${code}`, {
                        headers: {
                            "apiKey": "e6651d25-94b2-40a6-8eb2-1d2b3a512405"
                        }
                    })
                    const data = await ldbRaw.json()
                    const vgs = data.campaign?.visibilityGroups
                    if(vgs){
                        // match vg against campaigns
                        activeCampaign = campaigns.find(campaign => arraysShareElements(campaign.visibilityGroups, vgs))
                    }                    
                } catch (error) {
                    
                }
            }

            const obj = {
                elisafi: {
                    type,
                    productChoices: [{
                        productUid: uid,
                        productType: type,
                        priceId: priceOptions[period].id,
                        campaignIds: activeCampaign ? [activeCampaign.id] : [],
                        campaignCode: code ? [code] : []
                    }]
                },
                uid,
                code,
                period,
                price: priceOptions[period].price,
            }

            const existingItem = cart.find(item => (item.uid === uid) && (item.code === code) && (item.period === period))

            if(existingItem){
                existingItem.quantity++
            }else{
                cart.push({
                    ...obj,
                    quantity: 1
                })
            }

            updateStore({ cart })
        }else if(action === "cart:clear") {
            updateStore({ cart: [] })
        }else if(action === "cart:edit") {
            updateStore({ cart: payload })
        }else if(action === "cart:close") {
            cartBtn.checked = false
        }else if(action === "cart:open") {
            cartBtn.checked = true
        }else if(action === "cart:toggle") {
            cartBtn.checked = !cartBtn.checked
        }else if(action === "cart:scrollTo") {
            // window.scrollTo({
            //     top: 0,
            //     behavior: "smooth"
            // })
            document.documentElement.classList.remove("scroll-hide")
        }else if(action === "cart:render") {
            console.log("????")
        }else if(action === "checkout") {
            const cartItems = cart.reduce((acc, cur) => {
                return [
                    ...acc,
                    ...Array(cur.quantity).fill(cur.elisafi)
                ]
            }, [])
            console.log(cartItems)

            const encoded = btoa(JSON.stringify({cartItems}))
            const url = `https://elisa.fi/kauppa/ostoskori/uusi/v3/${encoded}?forward=/ostoskori`
            window.open(url, '_blank').focus();

        }else{
            console.error("Unknown action", action)
        }
    })     

    window.webshop = {
        period:{
            select(payload){
                eventDispatch("period:select", payload)
                return getStore().period
            },
            get(){
                return getStore().period
            }
        },
        variant:{
            select(payload, {
                focus = true
            } = {}){
                eventDispatch("variant:select", payload)
                if(focus) {
                    eventDispatch("variant:focus", payload.uid)
                }
            },
            focus(payload){
                eventDispatch("variant:focus", payload)
            }
        },
        cart: {
            add(payload, { 
                scrollTo = true,
                open = true
            } = {}){
                eventDispatch("cart:add", payload)
                if(open) {
                    eventDispatch("cart:open")
                }
                if(scrollTo) {
                    eventDispatch("cart:scrollTo")
                }
                return getStore().cart
            },
            remove(payload, {
                scrollTo = false,
                open = false
            } = {}){
                eventDispatch("cart:remove", payload)
                if(open) {
                    eventDispatch("cart:open")
                }
                if(scrollTo) {
                    eventDispatch("cart:scrollTo")
                }
                return getStore().cart
            },
            clear({
                close = true
            } = {}){
                eventDispatch("cart:clear")
                if(close) {
                    eventDispatch("cart:close")
                }
            },
            edit(func, {
                scrollTo = false,
                open = false
            } = {}){
                const cart = eventDispatch("cart:get")
                eventDispatch("cart:edit", func(cart))
                if(open) {
                    eventDispatch("cart:open")
                }
                if(scrollTo) {
                    eventDispatch("cart:scrollTo")
                }
                return getStore().cart
            },
            get(){
                return getStore().cart
            },            
            open(){
                eventDispatch("cart:open")
            },
            close(){
                eventDispatch("cart:close")
            },
            toggle(){
                eventDispatch("cart:toggle")
            },
            scrollTo(){
                eventDispatch("cart:scrollTo")
            }
        },
        checkout(){
            eventDispatch("checkout")
        }
    }

})();